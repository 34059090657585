import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, fertilizer, transport) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        // pdfContent.push({ text: vm.$t('movement.office_copy'), style: 'fertilizer1', alignment: 'center' })
        const mainbody = [
          [
            { text: vm.$t('movement.program_no'), style: 'td' },
            { text: vm.$n(data.program_no), style: 'td' },
            { text: vm.$t('movement.loriChalanNo'), style: 'td' },
            { text: data.lori_challan_no, style: 'td' }
          ],
          [
            { text: vm.$t('procurement.fertilizer_name'), style: 'td' },
            { text: fertilizer, style: 'td' },
            { text: vm.$t('movement.transport_agent'), style: 'td' },
            { text: transport, style: 'td' }
          ],
          [
            { text: vm.$t('movement.godownName'), style: 'td' },
            { text: vm.$i18n.locale === 'bn' ? data.godown_bn : data.godown, style: 'td' },
            { text: vm.$t('movement.deliveredDate'), style: 'td' },
            { text: dateFormat(data.month), style: 'td' }
          ],
          [
            { text: vm.$t('movement.deliveredAmount'), style: 'td' },
            { text: vm.$n(data.delivered_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
            { text: vm.$t('movement.receivedDate'), style: 'td' },
            { text: dateFormat(data.receive_date), style: 'td' }
          ],
          [
            { text: vm.$t('movement.receivedAmount'), style: 'td' },
            { text: vm.$n(data.received_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
            { text: vm.$t('movement.duration'), style: 'td' },
            { text: vm.$n(data.duration, { useGrouping: false }), style: 'td' }
          ],
          [
            { text: vm.$t('movement.difference_amount'), style: 'td' },
            { text: vm.$n(data.delivered_amount - data.received_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
            { text: vm.$t('movement.comments'), style: 'td' },
            { text: vm.$i18n.locale === 'bn' ? data.comments_bn : data.comments, style: 'td' }
          ]
        ]
        if (data.barcode_no) {
          mainbody.push([
            { text: vm.$t('movement.difference_amount'), style: 'td' },
            { text: vm.$n(data.delivered_amount - data.received_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
            { },
            { }
          ])
        }
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['*', '*', '*', '*'],
              body: mainbody
            }
          }
        )
        // pdfContent.push({ text: vm.$t('movement.driver_copy'), style: 'fertilizer', alignment: 'center' })
        // const mainbody2 = [
        //     [
        //       { text: vm.$t('movement.program_no'), style: 'td' },
        //       { text: data.program_no, style: 'td' },
        //       { text: vm.$t('movement.loriChalanNo'), style: 'td' },
        //       { text: data.lori_challan_no, style: 'td' }
        //     ],
        //     [
        //       { text: vm.$t('procurement.fertilizer_name'), style: 'td' },
        //       { text: fertilizer, style: 'td' },
        //       { text: vm.$t('movement.transport_agent'), style: 'td' },
        //       { text: transport, style: 'td' }
        //     ],
        //     [
        //       { text: vm.$t('movement.godownName'), style: 'td' },
        //       { text: vm.$i18n.locale === 'bn' ? data.godown_bn : data.godown, style: 'td' },
        //       { text: vm.$t('movement.deliveredDate'), style: 'td' },
        //       { text: data.month, style: 'td' }
        //     ],
        //     [
        //       { text: vm.$t('movement.deliveredAmount'), style: 'td' },
        //       { text: vm.$n(data.delivered_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
        //       { text: vm.$t('movement.receivedDate'), style: 'td' },
        //       { text: data.receive_date, style: 'td' }
        //     ],
        //     [
        //       { text: vm.$t('movement.receivedAmount'), style: 'td' },
        //       { text: vm.$n(data.received_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
        //       { text: vm.$t('movement.comments'), style: 'td' },
        //       { text: vm.$i18n.locale === 'bn' ? data.comments_bn : data.comments, style: 'td' }
        //     ]
        //   ]
        //   if (data.barcode_no) {
        //     mainbody2.push([
        //       { text: vm.$t('movement.difference_amount'), style: 'td' },
        //       { text: vm.$n(data.delivered_amount - data.received_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
        //       { text: vm.$t('movement.barcodeNo'), style: 'td' },
        //       { text: data.barcode_no ? data.barcode_no : null, style: 'td' }
        //     ])
        //   } else {
        //     mainbody2.push([
        //       { text: vm.$t('movement.difference_amount'), style: 'td' },
        //       { text: vm.$n(data.delivered_amount - data.received_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
        //       { },
        //       { }
        //     ])
        //   }
        // pdfContent.push(
        //     {
        //       table: {
        //         style: 'tableData',
        //         headerRows: 0,
        //         widths: ['*', '*', '*', '*'],
        //         body: mainbody2
        //       }
        //     }
        //   )
        //   pdfContent.push({ text: vm.$t('movement.receiver_copy'), style: 'fertilizer', alignment: 'center' })
        //   const mainbody3 = [
        //     [
        //       { text: vm.$t('movement.program_no'), style: 'td' },
        //       { text: data.program_no, style: 'td' },
        //       { text: vm.$t('movement.loriChalanNo'), style: 'td' },
        //       { text: data.lori_challan_no, style: 'td' }
        //     ],
        //     [
        //       { text: vm.$t('procurement.fertilizer_name'), style: 'td' },
        //       { text: fertilizer, style: 'td' },
        //       { text: vm.$t('movement.transport_agent'), style: 'td' },
        //       { text: transport, style: 'td' }
        //     ],
        //     [
        //       { text: vm.$t('movement.godownName'), style: 'td' },
        //       { text: vm.$i18n.locale === 'bn' ? data.godown_bn : data.godown, style: 'td' },
        //       { text: vm.$t('movement.deliveredDate'), style: 'td' },
        //       { text: data.month, style: 'td' }
        //     ],
        //     [
        //       { text: vm.$t('movement.deliveredAmount'), style: 'td' },
        //       { text: vm.$n(data.delivered_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
        //       { text: vm.$t('movement.receivedDate'), style: 'td' },
        //       { text: data.receive_date, style: 'td' }
        //     ],
        //     [
        //       { text: vm.$t('movement.receivedAmount'), style: 'td' },
        //       { text: vm.$n(data.received_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
        //       { text: vm.$t('movement.comments'), style: 'td' },
        //       { text: vm.$i18n.locale === 'bn' ? data.comments_bn : data.comments, style: 'td' }
        //     ]
        //   ]
        //   if (data.barcode_no) {
        //     mainbody3.push([
        //       { text: vm.$t('movement.difference_amount'), style: 'td' },
        //       { text: vm.$n(data.delivered_amount - data.received_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
        //       { text: vm.$t('movement.barcodeNo'), style: 'td' },
        //       { text: data.barcode_no ? data.barcode_no : null, style: 'td' }
        //     ])
        //   } else {
        //     mainbody3.push([
        //       { text: vm.$t('movement.difference_amount'), style: 'td' },
        //       { text: vm.$n(data.delivered_amount - data.received_amount) + '(' + vm.$t('movement.mTon') + ')', style: 'td' },
        //       { },
        //       { }
        //     ])
        //   }
        // pdfContent.push(
        //     {
        //       table: {
        //         style: 'tableData',
        //         headerRows: 0,
        //         widths: ['*', '*', '*', '*'],
        //         body: mainbody3
        //       }
        //     }
        //   )
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
              margin: [0, 15, 0, 10]
          },
          fertilizer1: {
              margin: [0, 5, 0, 10]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          krishi: {
            margin: [0, -5, 0, 5],
            alignment: 'center'
          },
          transport: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('lori-challan-recieve')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
