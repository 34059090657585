<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12" class="form-for-textarea">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                      <b-row>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Lori Challan No" vid="lori_challan_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="lori_challan_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.loriChalanNo')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    id="lori_challan_no"
                                    :options="loriChallanNoList"
                                    v-model="formData.lori_challan_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Program No"  vid="program_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="program_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.program_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="program_no"
                                    readonly
                                    v-model="formData.program_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Transport Agent"  vid="transport_agent_id" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="transport_agent_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.transport_agent')}}  <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="program_no"
                                    readonly
                                    v-model="info.transport_agent"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Godown Name"  vid="godown_infos_id" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="lc_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.godownName')}} <span class="text-danger"></span>
                                </template>
                                <b-form-input
                                    id="program_no"
                                    readonly
                                    v-model="info.godown"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Fertilizer Name"  vid="fertilizer_id" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="lc_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.fertilizer_name')}} <span class="text-danger"></span>
                                </template>
                                <b-form-input
                                    id="program_no"
                                    readonly
                                    v-model="info.fertilizer"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="delivered_amount"
                            >
                              <template v-slot:label>
                                {{$t('movement.deliveredAmount')}}
                              </template>
                              <b-form-input
                                  id="delivered_amount"
                                  readonly
                                  v-model="formData.delivered_amount"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_name"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.office')}}
                              </template>
                              <b-form-input
                                  id="office_name"
                                  readonly
                                  v-model="formData.office_name"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Receive Process"  vid="receive_process" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="receive_process"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.receiveProcess')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.receive_process"
                                  :options="recieveProcessList"
                                  id="receive_process"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                            <b-col lg="6" sm="12" v-if="formData.receive_process === 2">
                            <span>
                            <ValidationProvider name="Barcode No"  vid="barcode_no" :rules="rule">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="barcode_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.barcodeNo')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="barcode_no"
                                    type="text"
                                    v-model="formData.barcode_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                              </ValidationProvider>
                              </span>
                            </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Receive Amount" vid="received_amount" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="received_amount"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.receivedAmount')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="received_amount"
                                    v-model="formData.received_amount"
                                    @keyup="checker"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Delivery Date"  vid="month" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="month"
                              >
                                <template v-slot:label>
                                  {{$t('movement.deliveredDate')}}
                                </template>
                                <b-form-input
                                readonly
                                  v-model="formData.month"
                                  :placeholder="$t('globalTrans.select_date')"
                                  ></b-form-input>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Receive Data"  vid="receive_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="recieve_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.receivedDate')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  class="fromDate"
                                  v-model="formData.receive_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Difference"  vid="difference">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="difference"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.difference_amount')}}
                                </template>
                                <b-form-input
                                    readonly
                                    id="difference"
                                    type="text"
                                    v-model="formData.difference"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Comments"  vid="comment" rules="">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="comments"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.comments')}}
                                </template>
                                <b-form-textarea
                                  id="remarks"
                                  v-model="formData.comments"
                                  placeholder=""
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Comment (Bn)"  vid="comment_bn" rules="">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="commentsBn"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.commentsBn')}}
                                </template>
                                  <b-form-textarea
                                    id="comment_bn"
                                    v-model="formData.comments_bn"
                                    :placeholder="$t('movement.comments')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                      <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import flatpickr from 'flatpickr'
import { loriChalanRecieveStore, loriChalanRecieveUpdate, loriChalanRecieveInfo, loriChalanNoList } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.getLoriChallanNoList()
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.formData.difference = this.formData.delivered_amount - this.formData.received_amount
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      loading: false,
      formData: {
        id: '',
        lori_challan_no: 0,
        program_no: '',
        transport_agent_id: 0,
        godown_infos_id: 0,
        fertilizer_id: 0,
        fiscal_year_id: '',
        month: '',
        delivered_amount: '',
        difference: '',
        receive_process: 0,
        barcode_no: '',
        received_amount: '',
        receive_date: '',
        comments: '',
        comments_bn: '',
        office_id: '',
        office_name: ''
      },
      info: {
          transport_agent: '',
          godown: '',
          fertilizer: ''
      },
      rule: 'required',
      totalAllocatedAmount: 0,
      totalAvailableAmount: 0,
      chalanInfo: [],
      lcErrorMsg: '',
      loriChallanNoList: []
    }
  },
  computed: {
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    transportAgentList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    recieveProcessList: function () {
      const list = [
        { value: 1, text: 'Manual' },
        { value: 2, text: 'Barcode' }
      ]
      const listbn = [
        { value: 1, text: 'Manual' },
        { value: 2, text: 'Barcode' }
      ]
      return this.$i18n.locale === 'bn' ? listbn : list
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    'formData.receive_process': function (newVal, oldVal) {
      if (newVal !== 2) {
        this.formData.barcode_no = ''
        this.rule = ''
      }
    },
    'formData.lori_challan_no': function (newVal, oldVal) {
      this.chalanInfo = this.getChalanInfo(newVal)
    },
    'formData.received_amount': function (newVal, oldVal) {
      const delivered = this.formData.delivered_amount
      if (delivered && newVal > delivered) {
          this.formData.received_amount = 0
      }
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    checker (event) {
      const id = '#' + event.target.id
      const idValAmount = document.querySelector(id).value
      if (idValAmount > this.formData.delivered_amount) {
        const remainingIdVal = Math.floor(idValAmount / 10)
        document.querySelector(id).value = remainingIdVal
        this.formData.difference = this.formData.delivered_amount - idValAmount
      } else {
        this.formData.difference = this.formData.delivered_amount - idValAmount
      }
    },
     async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${loriChalanRecieveUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, loriChalanRecieveStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.loading = false
        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.success({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
        this.loading = false
      }
    },
    async getChalanInfo (chalanNo) {
      this.loading = true
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, loriChalanRecieveInfo + chalanNo)
      if (!result.success) {
        this.formData.program_no = ''
        this.formData.transport_agent_id = ''
        this.info.transport_agent = ''
        this.info.godown = ''
        this.info.fertilizer = ''
        this.formData.fiscal_year_id = ''
        this.formData.fertilizer_id = ''
        this.formData.delivered_amount = ''
        this.formData.month = ''
        this.formData.office_id = ''
        this.formData.office_name = ''
        this.loading = false
      } else {
        this.lcErrorMsg = ''
        const loriInfo = result.data
        const trnasport = this.transportAgentList.find(doc => doc.value === loriInfo.transport_agent_id)
        const godown = this.godownInfoList.find(doc => doc.value === loriInfo.godown_infos_id)
        const fertilizer = this.fertilizerNameList.find(doc => doc.value === loriInfo.fertilizer_id)
        const office = this.$store.state.commonObj.officeList.find(obj => obj.value === godown.sales_center_id)
        if (typeof loriInfo !== 'undefined' && trnasport) {
          this.formData.office_id = loriInfo.office_id
          this.formData.program_no = loriInfo.program_no
          this.formData.transport_agent_id = loriInfo.transport_agent_id
          this.info.transport_agent = this.$i18n.locale === 'bn' ? trnasport.text_bn : trnasport.text_en
          this.info.godown = this.$i18n.locale === 'bn' ? godown.text_bn : godown.text_en
          this.info.fertilizer = this.$i18n.locale === 'bn' ? fertilizer.text_bn : fertilizer.text_en
          this.formData.godown_infos_id = loriInfo.godown_infos_id
          this.formData.fertilizer_id = loriInfo.fertilizer_id
          this.formData.delivered_amount = loriInfo.total_allocated_amount
          this.formData.month = loriInfo.allocation_date
          this.formData.office_name = this.currentLocale === 'en' ? office?.text_en : office?.text_bn
        }
        this.loading = false
      }
    },
    async getLoriChallanNoList () {
      this.loading = true
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, loriChalanNoList)
      if (result.success) {
        this.loriChallanNoList = result.data
      } else {
        this.loriChallanNoList = []
      }
      this.loading = false
    }
  }
}
</script>
