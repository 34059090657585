<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                  {{ $t('movement.loriChallanRecieve') }}
                </list-report-head>
              </b-col>
            </b-row>
            <!-- <b-row>
                <b-table-simple>
                    <th class="text-center">{{$t('movement.office_copy')}}</th>
                </b-table-simple>
            </b-row> -->
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple bordered style="font-size:12px;">
                  <tbody>
                    <tr>
                      <th>{{ $t('movement.program_no') }}</th>
                      <td>{{ $n(formData.program_no, {useGrouping: false}) }}</td>
                      <th>{{ $t('movement.loriChalanNo') }}</th>
                      <td>{{ formData.lori_challan_no }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('procurement.fertilizer_name') }}</th>
                      <td>{{ getDataById(formData.fertilizer_id, 'fertilizer') }}</td>
                      <th>{{ $t('movement.transport_agent') }}</th>
                      <td>{{ getDataById(formData.transport_agent_id, 'agent') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.godownName') }}</th>
                      <td>{{ $i18n.locale === 'en' ? formData.godown : formData.godown_bn }}</td>
                      <th>{{ $t('movement.deliveredDate') }}</th>
                      <td>{{ formData.month | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.deliveredAmount') }}</th>
                      <td>{{ $n(formData.delivered_amount) + ' (' + $t('movement.mTon') + ')' }} </td>
                      <th>{{ $t('movement.receivedDate') }}</th>
                      <td>{{ formData.receive_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.receivedAmount') }}</th>
                      <td>{{ $n(formData.received_amount) + ' (' + $t('movement.mTon') + ')' }}</td>
                      <th>{{ $t('movement.duration') }}</th>
                      <td>{{ $n(formData.duration) }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.difference_amount') }}</th>
                      <td>{{ (formData.delivered_amount > formData.received_amount ? '(-)' : '') + $n(formData.delivered_amount - formData.received_amount) + ' (' + $t('movement.mTon') + ')'  }}</td>
                      <th>{{ $t('movement.comments') }}</th>
                      <td v-html="formData.comments_bn" v-if="$i18n.locale === 'bn'"></td>
                      <td v-html="formData.comments" v-else></td>
                    </tr>
                    <tr>
                      <th v-if="formData.barcode_no">{{ $t('movement.barcodeNo') }}</th>
                      <td v-if="formData.barcode_no">{{ formData.barcode_no }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <!-- <br>
            <b-row>
                <b-table-simple>
                    <th class="text-center">{{$t('movement.driver_copy')}}</th>
                </b-table-simple></b-row>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple bordered style="font-size:12px;color:#555555;line-height:2px">
                  <tbody>
                    <tr>
                      <th>{{ $t('movement.program_no') }}</th>
                      <td>{{ formData.program_no }}</td>
                      <th>{{ $t('movement.loriChalanNo') }}</th>
                      <td>{{ formData.lori_challan_no }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('procurement.fertilizer_name') }}</th>
                      <td>{{ getDataById(formData.fertilizer_id, 'fertilizer') }}</td>
                      <th>{{ $t('movement.transport_agent') }}</th>
                      <td>{{ getDataById(formData.transport_agent_id, 'agent') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.godownName') }}</th>
                      <td>{{ $i18n.locale === 'en' ? formData.godown : formData.godown_bn }}</td>
                      <th>{{ $t('movement.deliveredDate') }}</th>
                      <td>{{ formData.month | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.deliveredAmount') }}</th>
                      <td>{{ $n(formData.delivered_amount) + ' (' + $t('movement.mTon') + ')' }} </td>
                      <th>{{ $t('movement.receivedDate') }}</th>
                      <td>{{ formData.receive_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.receivedAmount') }}</th>
                      <td>{{ $n(formData.received_amount) + ' (' + $t('movement.mTon') + ')' }}</td>
                      <th>{{ $t('movement.comments') }}</th>
                      <td v-html="formData.comments_bn" v-if="$i18n.locale === 'bn'"></td>
                      <td v-html="formData.comments" v-else></td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.difference_amount') }}</th>
                      <td>{{ $n(formData.delivered_amount - formData.received_amount) + ' (' + $t('movement.mTon') + ')'  }}</td>
                      <th v-if="formData.barcode_no">{{ $t('movement.barcodeNo') }}</th>
                      <td v-if="formData.barcode_no">{{ formData.barcode_no }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <br>
            <b-row>
                <b-table-simple>
                    <th class="text-center">{{$t('movement.receiver_copy')}}</th>
                </b-table-simple></b-row>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple bordered style="font-size:12px;color:#555555;line-height:2px">
                  <tbody>
                    <tr>
                      <th>{{ $t('movement.program_no') }}</th>
                      <td>{{ formData.program_no }}</td>
                      <th>{{ $t('movement.loriChalanNo') }}</th>
                      <td>{{ formData.lori_challan_no }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('procurement.fertilizer_name') }}</th>
                      <td>{{ getDataById(formData.fertilizer_id, 'fertilizer') }}</td>
                      <th>{{ $t('movement.transport_agent') }}</th>
                      <td>{{ getDataById(formData.transport_agent_id, 'agent') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.godownName') }}</th>
                      <td>{{ $i18n.locale === 'en' ? formData.godown : formData.godown_bn }}</td>
                      <th>{{ $t('movement.deliveredDate') }}</th>
                      <td>{{ formData.month | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.deliveredAmount') }}</th>
                      <td>{{ $n(formData.delivered_amount) + ' (' + $t('movement.mTon') + ')' }} </td>
                      <th>{{ $t('movement.receivedDate') }}</th>
                      <td>{{ formData.receive_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.receivedAmount') }}</th>
                      <td>{{ $n(formData.received_amount) + ' (' + $t('movement.mTon') + ')' }}</td>
                      <th>{{ $t('movement.comments') }}</th>
                      <td v-html="formData.comments_bn" v-if="$i18n.locale === 'bn'"></td>
                      <td v-html="formData.comments" v-else></td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.difference_amount') }}</th>
                      <td>{{ $n(formData.delivered_amount - formData.received_amount) + ' (' + $t('movement.mTon') + ')'  }}</td>
                      <th v-if="formData.barcode_no">{{ $t('movement.barcodeNo') }}</th>
                      <td v-if="formData.barcode_no">{{ formData.barcode_no }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row> -->
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { seedFertilizerServiceBaseUrl } from '@/config/api_config'
// import { loriChalanRecieveShow } from '@/modules/seeds-fertilizer-service/fertilizer/movement/api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      const tmp = this.getDetailsData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      formData: {
        id: '',
        lori_challan_no: '',
        program_no: '',
        transport_agent_id: 0,
        godown_infos_id: 0,
        fertilizer_id: 0,
        fiscal_year_id: '',
        month: '',
        delivered_amount: '',
        receive_process: 0,
        barcode_no: '',
        received_amount: '',
        receive_date: '',
        comments: '',
        comments_bn: '',
        office_id: ''
      },
      totalAvailableAmount: 0,
      currentAvailableAmount: 0,
      challanDetailsInfo: []
    }
  },
  computed: {
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    transportAgentList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    recieveProcessList: function () {
      const list = [
        { value: 1, text: 'Manual' },
        { value: 2, text: 'Barcode' }
      ]
      const listbn = [
        { value: 1, text: 'Manual' },
        { value: 2, text: 'Barcode' }
      ]
      return this.$i18n.locale === 'bn' ? listbn : list
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFormattedDate (date) {
      if (date) {
          const dateArr = date.split('-')
          return this.$n(parseInt(dateArr[0]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[1]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[2]), { useGrouping: false })
      } else {
        return date
      }
    },
    getDetailsData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getDataById (Id, type) {
      if (Id) {
        var data
        switch (type) {
          case 'fertilizer':
            data = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.value === Id)
            break
          case 'agent':
            data = this.$store.state.SeedsFertilizer.commonObj.transportAgentList.find(item => item.value === Id)
            break
          default:
            break
        }
        if (data) {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
        } else {
          return null
        }
      } else {
        return null
      }
    },
    pdfExport () {
        const fertilizer = this.getDataById(this.formData.fertilizer_id, 'fertilizer')
        const transport = this.getDataById(this.formData.transport_agent_id, 'agent')
        const reportTitle = this.$t('movement.loriChallanRecieve')
        ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this, fertilizer, transport)
    }
  }
}
</script>
